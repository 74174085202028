import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
const root = ReactDOM.createRoot(document.getElementById('root'));
document.body.style.backgroundColor = "#ff0077";
document.body.style.color = "white";
document.body.style.textAlign = "center";
root.render(
  <React.StrictMode>
     <div>
       <h1>GlobalRemote</h1>
       <p>Let us connect to your GlobalShut computer. This application will act as a global remote.</p>
       <form onSubmit={function(e) {
         e.preventDefault();
         axios.post(`${(document.getElementById("host").value).toString()}/verify_password`, {
          password: `${(document.getElementById("password").value).toString()}`,
        })
        .then(function (response) {
          console.log(response);
          if(response.data.verified === "yes") {
             openTheRemote((document.getElementById("host").value).toString(), (document.getElementById("password").value).toString());
          }else{
             alert("Sorry, But the password is Invalid! Failed to open GlobalRemote!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
       }}>
          <input id='host' placeholder='Link to PC' required></input>
          <br></br>
          <br></br>
          <input id='password' type='password' placeholder='********************' required></input>
          <br></br>
          <br></br>
          <button type='submit'>Connect</button>
       </form>
       <br></br>
       <h1>Other methods for connection</h1>
       <p>Currently there is no other method for connection to your PC. You have to provide the password using which you started the GlobaLShut app on your PC.</p>
       <a href='https://site.globalshut.namishkumar.in'><button>Download GlobalShut</button></a>
     </div>
  </React.StrictMode>
);
function openTheRemote(host, password) {
   root.render(
      <React.StrictMode>
         <div>
          <br></br>
          <br></br>
              <form id='shutForm' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/api_shutdown`, {
                  password: `${password}`,
                  timeInSeconds: `0`,
                  message:`Shutdown Immediately!`
                })
                .then(function (response) {
                  console.log(response);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      <img src='./power_settings_new_FILL0_wght400_GRAD0_opsz48.svg'></img>
                      S
                  </button> 
              </form>
              <br></br>
              <br></br>
              <form id='shutForm1' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/api_shutdown`, {
                  password: `${password}`,
                  timeInSeconds: `0`,
                  message:`Shutdown Immediately!`
                })
                .then(function (response) {
                  console.log(response);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      <img src='./power_settings_new_FILL0_wght400_GRAD0_opsz48.svg'></img>
                      R
                  </button> 
              </form>
              <br></br>
              <br></br>
              <h1>Volume</h1>
              <br></br>
              <form id='shutForm1' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/change/vol/increase`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      <img src='7f69453afed3ed4fab350adc5b0ebd2e.svg'></img>
                  </button> 
              </form>
              <br></br>
              <br></br>
              
              <form id='shutForm2' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/change/vol/decrease`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      <img src='988ee2db0c37f7bd8970d23dbb59c69b.svg'></img>
                  </button> 
              </form>
              <br></br>
              <br></br>
              <h1>Window</h1>
              <br></br>
              <form id='shutForm3' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/switch_window`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      <img src='0e905d3115f43ac73214892f7e3b3d8b.svg'></img>
                  </button> 
              </form>
              <br></br>
              <br></br>
              <form id='shutForm4' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/close_window`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      <img src='0f320275e852635b78e8ae57ad75b660.svg'></img>
                  </button> 
              </form>
              <br></br>
              <br></br>
              <h1>Keys</h1>
              <br></br>
              <form id='shutForm5' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/up_arrow`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      Up Key
                  </button> 
              </form>
              <br></br>
              <br></br>
              <form id='shutForm6' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/down_arrow`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      Down Key
                  </button> 
              </form>
              <br></br>
              <br></br>
              <form id='shutForm7' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/enter_key`, {
                  password: `${password}`,
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      ENTER
                  </button> 
              </form>
              <br></br>
              <br></br>
              <h1>Keyboard</h1>
              <p>Send a text to the PC</p>
              <form id='shutForm8' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/type_a_text`, {
                  password: `${password}`,
                  text: `${(document.getElementById("txt").value).toString()}`
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                <input id='txt' placeholder='Enter the text' type='text' required></input>
                <br></br>
                <br></br>
                 <button className='r' type='submit'>
                      Type the text
                  </button> 
              </form>
              <br></br>
              <br></br>
              <p>Erase from right to left</p>
              <form id='shutForm9' onSubmit={function(e){
                 e.preventDefault();
                 axios.post(`${host}/program/press/backspace`, {
                  text: `${(document.getElementById("txt").value).toString()}`
                })
                .then(function (response) {
                  console.log(response.data);
                })
                .catch(function (error) {
                  console.log(error);
                });
              }}>
                 <button className='r' type='submit'>
                      BACKSPACE
                  </button> 
              </form>
              <br></br>
              <br></br>

         </div>
      </React.StrictMode>
   );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
